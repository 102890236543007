<template>
  <van-checkbox v-bind="attrs" :disabled="disabled">
    <slot></slot>
    <template #icon="props">
      <div
        v-if="props.disabled && !props.checked"
        class="h-4.5 w-4.5 flex items-center justify-center rounded-1 bg-[#777474]"
      ></div>
      <div v-else-if="props.checked" class="h-4.5 w-4.5 flex items-center justify-center rounded-1" style="background-color: var(--tc-color-primary)">
        <NuxtIcon name="my-icon:mark" text="base white"></NuxtIcon>
      </div>
      <div v-else class="box-border h-4.5 w-4.5 rounded-1" b="1.5 solid #E4E4E4"></div>
    </template>
  </van-checkbox>
</template>

<script setup lang="ts">
defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
})

const attrs: Record<string, unknown> = useAttrs()
</script>
